// PRIMARY COLOR
$primary: #CF7500;
$primary-light: #ffab40ff;
$primary-lighter: #db4c58;
$primary-darkest: #ffab40ff;

// ALERT
$alert-secondary: #21BF73;

// WARNING
$rose: #DD2C00;
$rose-light: #FFE4DD;

// VARIANT
$mist: #BDBDBD;
$misty-light: #EFF0F2;
$misty-darkest: #868686;

// Z-INDEX
$navbar-z-index: 10;
$sidebar-z-index: 11;
$modal-bg-z-index: 50;