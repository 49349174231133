@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 200;
  src: local('Avenir Book'), local('Avenir-Book'), url('../font/Avenir-Book.woff2') format('woff2');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: local('Avenir Medium'), local('Avenir-Medium'), url('../font/Avenir-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  src: local('Avenir Black'), local('Avenir-Black'), url('../font/Avenir-Black.woff2') format('woff2');
}

html, body {
  font-family: 'Avenir' !important;
  font-size: 14px;
  color: black;
}